import * as React from "react"
import { useState } from "react"
import { graphql, Link } from "gatsby"
import { Layout } from "../../../components/layout"
import isEqual from "lodash.isequal"
import { StoreContext } from "../../../context/store-context"
import { AddToCart } from "../../../components/add-to-cart"
import { NumericInput } from "../../../components/numeric-input"
import { formatPrice } from "../../../utils/format-price"
import { Seo } from "../../../components/seo"
import Helmet from "react-helmet"

import {
  productBox,
  container,
  header,
  productImageWrapper,
  productImageList,
  productImageListItem,
  scrollForMore,
  noImagePreview,
  optionsWrapper,
  priceValue,
  selectVariant,
  labelFont,
  breadcrumb,
  tagList,
  addToCartStyle,
  metaSection,
  productDescription,
} from "./product-page.module.css"
import { MdFullscreen } from "react-icons/md"
import { Gallery, Item } from "react-photoswipe-gallery"
import "photoswipe/dist/photoswipe.css"
import SocialShareLinks from "../../../components/SocialShareLinks"

export default function Product({ data: { product, suggestions } }) {
  // console.group("product")
  // console.log(product)
  // console.groupEnd()

  const [currentThumbIndex, setCurrentThumbIndex] = useState(0)

  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    title,
    description,
    descriptionHtml,
    images,
    images: [firstImage],
  } = product
  const { client } = React.useContext(StoreContext)

  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1)
  const [focusAltText, setFocusAltText] = React.useState(firstImage.altText)
  const [variantSelected, setVariantSelected] = useState(false)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant
  // console.log(productVariant)
  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  )

  const checkAvailablity = React.useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        const result =
          fetchedProduct?.variants.filter(
            (variant) => variant.id === productVariant.storefrontId
          ) ?? []

        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [productVariant.storefrontId, client.product]
  )

  const handleOptionChange = (index, event) => {
    const value = event.target.value

    if (value === "") {
      setVariantSelected(false)
      return
    } else {
      setVariantSelected(true)
    }

    const currentOptions = [...variant.selectedOptions]

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find((variant) => {
      return isEqual(currentOptions, variant.selectedOptions)
    })

    setVariant({ ...selectedVariant })
  }

  React.useEffect(() => {
    checkAvailablity(product.storefrontId)
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId])

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  )

  const hasVariants = variants.length > 1
  const hasImages = images.length > 0
  const hasMultipleImages = true || images.length > 1

  let galleryImages = []
  images.forEach((image) => {
    galleryImages.push({
      original: image.original,
      thumbnail: image.thumbnail.images.fallback.src,
      srcSet: image.thumbnail.images.sources[0].srcSet,
      originalHeight: image.height,
      originalWidth: image.width,
      altText: image.altText,
    })
  })

  let availableForSale = false
  variants.forEach((el) => {
    if (el.availableForSale) availableForSale = true
  })

  return (
    <Layout>
      <Helmet>
        <body className="product-page" />

        {/* OpenGraph tags */}
        <meta property="og:image:width" content={firstImage.thumbnail.width} />
        <meta
          property="og:image:height"
          content={firstImage.thumbnail.height}
        />
        <meta property="product:price:amount" content={price} />
        <meta property="product:price:currency" content="USD" />
        {availableForSale ? (
          <meta property="og:availability" content="instock" />
        ) : (
          <meta property="og:availability" content="out of stock" />
        )}

        {/* END OpenGraph tags */}
      </Helmet>

      {firstImage ? (
        <Seo
          type="product"
          title={title}
          description={description}
          image={firstImage.thumbnail.images.fallback.src}
        />
      ) : undefined}
      <div
        className={container}
        style={{
          maxWidth: "800px",
          margin: "0 auto",
        }}
      >
        <h2>{title}</h2>
        <div className={productBox}>
          {hasImages && (
            <div className={productImageWrapper}>
              <div id="product-wrapper">
                <ul id="product-thumbnails">
                  {galleryImages.map((image, index) => {
                    return (
                      <li
                        className={currentThumbIndex === index ? "active" : ""}
                        key={index}
                        onClick={() => {
                          setCurrentThumbIndex(index)
                          setFocusAltText(image.altText)
                        }}
                      >
                        <img src={image.thumbnail} />
                      </li>
                    )
                  })}
                </ul>
                <div className="product-image-wrapper">
                  <ul id="product-focus" className="neu">
                    <Gallery id="gallery">
                      {galleryImages.map((image, index) => {
                        return (
                          <li
                            key={index}
                            className={
                              index === currentThumbIndex ? "active" : "hidden"
                            }
                          >
                            <Item
                              original={image.original}
                              originalSrcset={image.srcSet}
                              width={image.width}
                              height={image.height}
                            >
                              {({ ref, open }) => (
                                <img
                                  className="gallery-image"
                                  ref={ref}
                                  onClick={open}
                                  src={image.thumbnail}
                                  alt={image.thumbnail}
                                  originalsrcset={image.srcSet}
                                />
                              )}
                            </Item>
                          </li>
                        )
                      })}
                    </Gallery>
                  </ul>
                  <div className="altText">{focusAltText}</div>
                </div>
              </div>
            </div>
          )}
          {!hasImages && (
            <span className={noImagePreview}>No Preview image</span>
          )}
          <div>
            {/* BREADCRUMB */}
            {/* <div className={breadcrumb}>
              <Link to={product.productTypeSlug}>{product.productType}</Link>
              <ChevronIcon size={12} />
            </div> */}
            <div
              className="product-description"
              dangerouslySetInnerHTML={{ __html: descriptionHtml }}
            />
            {/*  TODO: This conditional is temporary for planner launch */}
            {productVariant.compareAtPrice > variant.price ? (
              <>
                <p className="original-price">
                  List Price: <span>${productVariant.compareAtPrice}</span>
                </p>
                <p className="save-price">
                  You save{" "}
                  <span>
                    $
                    {parseFloat(
                      productVariant.compareAtPrice - variant.price
                    ).toFixed(2)}
                  </span>
                </p>
                <div className="price">Sale Price: {price}</div>
              </>
            ) : (
              <div className="price">{price}</div>
            )}
            <fieldset className={optionsWrapper}>
              {hasVariants &&
                options.map(({ id, name, values }, index) => (
                  <div className={selectVariant} key={id}>
                    <select
                      aria-label="Variants"
                      onChange={(event) => handleOptionChange(index, event)}
                    >
                      <option value="">{`Select ${name}`}</option>
                      {values.map((value) => (
                        <option value={value} key={`${name}-${value}`}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>
                ))}
            </fieldset>
            <div className={addToCartStyle}>
              <NumericInput
                aria-label="Quantity"
                onIncrement={() => setQuantity((q) => Math.min(q + 1, 20))}
                onDecrement={() => setQuantity((q) => Math.max(1, q - 1))}
                onChange={(event) => setQuantity(event.currentTarget.value)}
                value={quantity}
                min="1"
                max="20"
              />
              <AddToCart
                variantId={productVariant.storefrontId}
                quantity={quantity}
                available={available}
                disabled={!variantSelected}
              />
            </div>
            <SocialShareLinks
              title={title}
              img={firstImage?.thumbnail.images.fallback.src}
            />
            {/* METASECTION */}
            {/* <div className={metaSection}>
              <span className={labelFont}>Type</span>
              <span className={tagList}>
                <Link to={product.productTypeSlug}>{product.productType}</Link>
              </span>
              <span className={labelFont}>Tags</span>
              <span className={tagList}>
                {product.tags.map((tag) => (
                  <Link to={`/search?t=${tag}`}>{tag}</Link>
                ))}
              </span>
            </div> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!, $productType: String!) {
    product: shopifyProduct(id: { eq: $id }) {
      title
      description
      descriptionHtml
      productType
      productTypeSlug: gatsbyPath(
        filePath: "/shop/{ShopifyProduct.productType}"
      )
      tags
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      variants {
        availableForSale
        compareAtPrice
        id
        price
        selectedOptions {
          value
          name
        }
        storefrontId
        title
      }
      images {
        id
        height
        width
        thumbnail: gatsbyImageData(
          layout: CONSTRAINED
          width: 1920
          aspectRatio: 1
          placeholder: TRACED_SVG
        )
        original: originalSrc
        altText
      }
      options {
        name
        values
        id
      }
    }
    suggestions: allShopifyProduct(
      limit: 3
      filter: { productType: { eq: $productType }, id: { ne: $id } }
    ) {
      nodes {
        ...ProductCard
      }
    }
  }
`
